import YggdrasilBusiness from "@/business/YggdrasilBusiness.js";
import Http from "@/commons/Http";

const path = 'api/endereco';

export default {

  async getEndereco(sort, direction, page, size, filters = {}) {
    return Http.post(`${path}/pagination?sort=${sort || 'c.cep'},${direction || 'asc'}&page=${page || 1}&size=${size || 10}`, filters);
  },

}
