import Http from "@/commons/Http";
import Utils from "@/commons/Utils.js";

const path = 'api/tabela-custa';

export default {

  async getTiposRegistro(dominio = null) {
    let tipos = await Http.get(`${path}/tipo-registro/${dominio || ''}`).catch((e) => e);
    return Utils.uniqBy(tipos, 'id');
  },

  async getTabelas(estado) {
    return await Http.get(`${path}/${estado}`);
  },

  async getCustasTabela(estado, inicio, fim) {
    return await Http.get(`${path}/${estado}/custas/${inicio}/${fim}`);
  },

  listTiposRegistro(estado){

    const tipos = [
      { id : "NORMAL", nome : "Normal"},
      { id : "PRIMEIRA_AQUISICAO", nome : "Primeira Aquisição - SFH"},
      { id : "PMCMV", nome : "Programa Minha Casa Minha Vida - PMCMV"},
      { id : "PCVA", nome : "Programa Casa Verde e Amarela - PCVA"},
      { id : "FAR", nome : "Fundo de Arrendamento Residencial - FAR"},
    ];

    if (!estado || estado == 'MS') {
      tipos.push({ id : "PRIMEIRA_AQUISICAO_25", nome : "Primeira Aquisição - SFH (25%)"});
      tipos.push({ id : "PAR", nome : "Programa de Arrendamento Residencial - PAR"});
    }

    if(!estado || estado == 'GO'){
      tipos.push({ id : 'ADJUDICACAO_COMPULSORIA', nome : 'Adjudicação Compulsória'});
      tipos.push({ id : "DESAPROPRIACAO", nome : "Desapropriação Empresas Públicas e Sociedades de Economia Mista"});
      tipos.push({ id : 'CEDULA', nome : 'Cédula ou Aditivo de Cédula'});
      tipos.push({ id : 'CEDULA_RURAL', nome : 'Cédula Rural ou Aditivo de Cédula'});
      tipos.push({ id : 'PAGAMENTO_POSTERIOR', nome : 'Pagamento Posterior'});
      tipos.push({ id : 'USUCAPIAO_EXTRAJUDICIAL', nome : 'Usucapião Extrajudicial'});
      tipos.push({ id : 'ISENTO', nome : 'Prenotação Isenta'});
      tipos.push({ id : 'PAGAMENTO_POSTERIOR_CNIB', nome : 'Pagamento Posterior (CNIB)'});
      tipos.push({ id : 'CNIB_CANCELAMENTO', nome : 'Cancelamento de Ordem CNIB'});
      tipos.push({ id : 'DESAPROPRIACAO', nome : 'Desapropriação Empresas Públicas e Sociedades de Economia Mista'});
      tipos.push({ id : 'PAGAMENTO_POSTERIOR_PENHORA_TRABALHISTA', nome : 'Pagamento Posterior (Penhora Trabalhista)'});
      tipos.push({ id : 'DESENQUADRAMENTO', nome : 'Desenquadramento do PMCMV'});
      tipos.push({ id : 'REURB', nome : 'Reurb (Lei 13.465/2017)'});
      tipos.push({ id : "PAR", nome : "Programa de Arrendamento Residencial - PAR"});
    }

    if(!estado || estado == 'SC'){
      tipos.push({ id : "PAR", nome : "Programa de Arrendamento Residencial - PAR"});
      tipos.push({ id : "COHAB", nome : "COHAB"});
      tipos.push({ id : "CONSTRICAO_JUDICIAL", nome : "Constrições judiciais"});
      tipos.push({ id : "OUTRAS_AUTARQUIAS", nome : "Autarquias de outros Estados"});
      tipos.push({ id : "NORMAL_SEM_FRJ", nome : "Normal (Sem incidência de FRJ)"});
      tipos.push({ id : "NORMAL_DESCONTO_FRJ", nome : "Normal (FRJ 5%) - Crédito Rural"});
      tipos.push({ id : "PAGAMENTO_DIFERIDO", nome : "Normal (pagamento diferido)"});

    }

    if(!estado || estado == 'CE'){
      tipos.push({ id : "PAR", nome : "Programa de Arrendamento Residencial - PAR"});
      tipos.push({ id : "COHAB", nome : "COHAB"});
      tipos.push({ id : "MANDADO_JUDICIAL", nome : "Mandado Judicial"});
    }

    if(!estado || estado == 'PE'){
      tipos.push({ id : "AQUISICAO_COOPERATIVA_HABITACIONAL", nome : "Aquisição por Cooperativa Habitacional"});
      tipos.push({id:"REURB_S", nome: "Reurb-S"});
    }

    if(!estado || estado == 'PR') {
      tipos.push({ id : "PRIMEIRA_AQUISICAO_25", nome : "Primeira Aquisição - SFH (25%)"});
      tipos.push({ id : "DESCONTO_ENTES_PUBLICOS", nome : "Entes Públicos"});
      tipos.push({ id : "COHAPAR", nome : "COHAPAR"});
      tipos.push({ id : "ORDEM_JUDICIAL", nome: "Ordem Judicial"});
    }

    if(!estado || estado == 'RO') {
      tipos.push({ id : "PRIMEIRA_AQUISICAO_25", nome : "Primeira Aquisição - SFH (25%)"});
      tipos.push({ id : "PMCMV_25", nome : "Programa Minha Casa Minha Vida - PMCMV (25%)"});

    }

    if(!estado || estado == 'MG'){
      tipos.push({ id : "PAR", nome : "Programa de Arrendamento Residencial - PAR"});
      tipos.push({ id : "DOACAO_ESTADO", nome : "Doação de Imóveis pelo Estado"});
      tipos.push({ id : "INDISPONIBILIDADE", nome : "Indisponibilidade de Bens"});
      tipos.push({ id : "MANDADO_JUDICIAL", nome : "Mandado Judicial"});
      tipos.push({ id : "CANCELAMENTO_PENHORA_TRABALHISTA", nome : "Cancelamento de Penhora Trabalhista"});
      tipos.push({ id : "PENHORA_ONLINE_SAEC", nome : "Penhora Online - SAEC"});
    }

    if(!estado || estado == 'SP'){
      tipos.push({ id : "PRIMEIRA_AQUISICAO_25", nome : "Primeira Aquisição - SFH (25%)"});
      tipos.push({ id : "DESCONTO_ENTES_PUBLICOS", nome : "Entes Públicos"});
      tipos.push({ id : "ISENTO_FUNDOS", nome : "Isento de Fundos"});
      tipos.push({ id : "SOCIEDADE_SEM_FINS_LUCRATIVOS", nome : "Sociedade sem Fins Lucrativos"});
      tipos.push({ id : "EMPREENDIMENTO_INTERESSE_SOCIAL_ZEIS", nome : "Empreendimentos de Interesse Social - Zeis"});
    }

    return Utils.uniqBy(tipos, 'id');

  }

}
