import VueBusiness from "@/business/VueBusiness.js";

export default {

  getAtribuicoes(){
    return [
      {id: 'RI', nome: 'RI', nomeCompleto: 'Registro de Imóveis'},
      {id: 'RTD', nome: 'RTD', nomeCompleto: 'Registro de Títulos e Documentos'},
      {id: 'RCPJ', nome: 'RCPJ', nomeCompleto: 'Registro Civil de Pessoas Jurídicas'},
    ];
  },

  getAtribuicoesAtivas(){
    return this.getAtribuicoes().filter(e => VueBusiness.getVueRoot().atribuicaoAtiva(e.id));
  },

  dominiosAtivos(){
    return this.getDominios().filter(e => VueBusiness.getVueRoot().atribuicaoAtiva(e.atribuicao));
  },

  getSimpleDominiosAtivos(){
    return this.dominiosAtivos().map(e => ({id: e.id, nome: e.nomeCompleto}));
  },

  getDominios() {
    return [
      {id: 'PROTOCOLO_RI', nome: 'Protocolo RI', tipo : 'PROTOCOLO', atribuicao : 'RI', genero: 'o', nomeCompleto: 'Protocolo RI'},
      {id: 'CERTIDAO_RI', nome: 'Certidão RI', tipo : 'PEDIDO_CERTIDAO', atribuicao : 'RI', genero: 'o', nomeCompleto: 'Pedido de Certidão RI'},
      {id: 'PROTOCOLO_RTD', nome: 'Protocolo RTD', tipo : 'PROTOCOLO', atribuicao : 'RTD', genero: 'o', nomeCompleto: 'Protocolo RTD'},
      {id: 'CERTIDAO_RTD', nome: 'Certidão RTD', tipo : 'PEDIDO_CERTIDAO', atribuicao : 'RTD', genero: 'o', nomeCompleto: 'Pedido de Certidão RTD'},
      {id: 'PROTOCOLO_RCPJ', nome: 'Protocolo RCPJ', tipo : 'PROTOCOLO', atribuicao : 'RCPJ', genero: 'o', nomeCompleto: 'Protocolo RCPJ'},
      {id: 'CERTIDAO_RCPJ', nome: 'Certidão RCPJ', tipo : 'PEDIDO_CERTIDAO', atribuicao : 'RCPJ', genero: 'o', nomeCompleto: 'Pedido de Certidão RCPJ'},
      {id: 'NOTIFICACAO', nome: 'Notificação', tipo : 'AUXILIAR', atribuicao : 'GERAL', genero: 'a', nomeCompleto: 'Notificação'},
      {id: 'OCORRENCIA', nome: 'Ocorrência', tipo : 'AUXILIAR', atribuicao : 'GERAL', genero: 'a', nomeCompleto: 'Ocorrência'},
      {id: 'OFICIO', nome: 'Ofício', tipo : 'AUXILIAR', atribuicao : 'GERAL', genero: 'o', nomeCompleto: 'Ofício'},
      {id: 'PERSONALIZADO', nome: 'Processos Personalizados', tipo : 'AUXILIAR', atribuicao : 'GERAL', genero: 'o', nomeCompleto: 'Processos Personalizados'},
    ];
  },

  getDominiosProtocoloRtdRcpjUnificado() {
    return [
      {id: 'PROTOCOLO_RI', nome: 'Protocolo RI', tipo : 'PROTOCOLO', atribuicao : 'RI', genero: 'o', nomeCompleto: 'Protocolo RI'},
      {id: 'PROTOCOLO_RTD_RCPJ', nome: 'Protocolo RTD e RCPJ', tipo : 'PROTOCOLO', atribuicao : 'RTD_RCPJ', genero: 'o', nomeCompleto: 'Protocolo RTD e RCPJ'},
    ];
  },

  getDominio(dominio) {
    return this.getDominios().find(d => d.id === dominio);
  },

  getDominioCertidao(dominio) {
    switch (dominio) {
      case 'PROTOCOLO_RI':
      case 'CERTIDAO_RI':
        return 'CERTIDAO_RI';
      case 'PROTOCOLO_RTD':
      case 'CERTIDAO_RTD':
        return 'CERTIDAO_RTD';
      case 'PROTOCOLO_RCPJ':
      case 'CERTIDAO_RCPJ':
        return 'CERTIDAO_RCPJ';
      default:
        return null;
    }
  },

  isProtocolo(dominio) {
    return this.getDominio(dominio)?.tipo == 'PROTOCOLO';
  },

  isPedidoCertidao(dominio) {
    return this.getDominio(dominio)?.tipo == 'PEDIDO_CERTIDAO';
  },

  isProtocoloOrCertidao(dominio) {
    return this.isProtocolo(dominio) || this.isPedidoCertidao(dominio);
  },

  isDominioRI(dominio) {
    return this.getDominio(dominio)?.atribuicao == 'RI';
  },

  isDominioRTD(dominio) {
    return this.getDominio(dominio)?.atribuicao == 'RTD';
  },

  isDominioRCPJ(dominio) {
    return this.getDominio(dominio)?.atribuicao == 'RCPJ';
  },

  isTarefa(dominio) {
    return this.getDominio(dominio)?.id == 'PERSONALIZADO';
  },

  dominiosAuxiliares(){
    return ['NOTIFICACAO', 'OFICIO', 'OCORRENCIA', 'PERSONALIZADO'];
  },

  isDominioAuxiliar(dominio) {
    return this.dominiosAuxiliares().includes(dominio);
  },

  isFichaExterna(ficha) {
    return ficha?.tipoFicha === 'externa';
  },

  getTiposProtocoloPorDominio(dominio, simples = false){

    if(this.isPedidoCertidao(dominio)){
      return [
        {id: 'NORMAL', nome: 'Normal'},
        {id: 'ORCAMENTO', nome: 'Orçamento'}
      ];
    }

    if(this.isProtocolo(dominio)){
      if(simples && ['PROTOCOLO_RI'].includes(dominio)){
        return [
          {id: 'NORMAL', nome: 'Normal'},
          {id: 'PROCESSO_INTERNO', nome: 'Processo Interno'}
        ];
      }

      return [
        {id: 'NORMAL', nome: 'Normal'},
        {id: 'EXAME_CALCULO', nome: 'Exame e Cálculo'},
        ['PROTOCOLO_RI'].includes(dominio) ? {id: 'PROCESSO_INTERNO', nome: 'Processo Interno'} : null,
        {id: 'ORCAMENTO', nome: 'Orçamento'}
      ].filter(e => e);
    }

    if(dominio == 'OFICIO'){
      return [
        {id: 'ENVIADO', nome: 'Enviado'},
        {id: 'RECEBIDO', nome: 'Recebido'}
      ];
    }

    return [];

  },

  getDominioProtocolo() {
    if (VueBusiness.getVueRoot().config.livros.protocoloSequenciaIgualRtdRcpj) {
      return this.getDominiosProtocoloRtdRcpjUnificado();
    } else {
      return this.dominiosAtivos().filter(e => e.tipo === 'PROTOCOLO').map(e => ({id: e.id, nome: e.nomeCompleto}));
    }
  }

}
